// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,\r\nbody,\r\ndiv,\r\nh1,\r\nh2,\r\nh3,\r\nh4,\r\nh5,\r\nh6,\r\np,\r\nimg,\r\ndl,\r\ndt,\r\ndd,\r\nol,\r\nul,\r\nli,\r\nform,\r\ntable,\r\ntr,\r\nth,\r\ntd {\r\n  margin: 0;\r\n  padding: 0;\r\n  border: 0;\r\n}\r\nh1,\r\nh2,\r\nh3,\r\nh4,\r\nh5,\r\nh6 {\r\n  font-weight: normal;\r\n}\r\ntable {\r\n  border-collapse: collapse;\r\n  border-spacing: 0;\r\n}\r\nimg {\r\n  border: 0;\r\n}\r\nol,\r\nul {\r\n  list-style: none;\r\n}\r\ni,\r\nb,\r\nem,\r\nstrong {\r\n  font-style: normal;\r\n  text-decoration: none;\r\n  font-weight: normal;\r\n}\r\n\r\na,\r\nimg {\r\n  -webkit-touch-callout: none;\r\n  text-decoration: none;\r\n}\r\n\r\nhtml {\r\n  -webkit-text-size-adjust: 100%; /* 防止页面resize之后，字体未重新渲染的bug */\r\n}\r\n\r\n* {\r\n  box-sizing: border-box;\r\n}\r\n*::selection {\r\n  color: white;\r\n  background-color: #0a58d4;\r\n}\r\nhtml {\r\n  min-height: 100%;\r\n  -webkit-font-smoothing: auto !important;\r\n}\r\nbody,\r\n#src_root__3TmMh {\r\n  min-height: 100vh;\r\n}\r\nhtml,\r\nbody {\r\n  font-size: 14px;\r\n  font-family: \"roboto\", \"Open Sans\", \"Helvetica Neue\", BlinkMacSystemFont,\r\n    \"PingFang SC\", \"Hiragino Sans GB\", \"Microsoft YaHei\", Arial, sans-serif;\r\n  min-width: 1280px;\r\n}\r\n#src_root__3TmMh {\r\n  min-height: 100%;\r\n  min-height: 100vh;\r\n  display: table-cell;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n}\r\n\r\n.src_ie_fix__jJBh2 #src_header_side__3NPxm {\r\n  width: 500px;\r\n}\r\n", ""]);
// Exports
exports.locals = {
	"root": "src_root__3TmMh",
	"ie_fix": "src_ie_fix__jJBh2",
	"header_side": "src_header_side__3NPxm"
};
module.exports = exports;
